.footerCopyrightNotice {
  padding-left: 64px;
  padding-right: 32px;
}

.footerNav {
  flex: 1;
  color: whitesmoke;
  height: 100%;
}

.footerNavLink {
  padding: 16px 0;
  color: rgba(255, 255, 255, 0.55) !important;
}

.footerNavLink:hover {
  color: #ffffff !important;
}