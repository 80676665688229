.introductionContainer {
  padding: 0 80px;
}

.introductionTitle {
  white-space: nowrap;
}

.introductionDescription {
  max-width: 70%;
  padding: 8px 0;
}

.introductionDetails {
  max-width: 100%;
}

.videoSectionTitle {
  white-space: nowrap;
  max-width: 100%;
  margin-top: 5vh;
}

.videoSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-height: 100%;
  padding: 1em 0 3em 0;
}


@keyframes robotanim {
  0% {
    margin-bottom: 0;
    margin-top: 6%;
  }
  25% {
    margin-bottom: 4%;
    margin-top: 2%
  }
  50% {
    margin-bottom: 6%;
    margin-top: 0
  }
  75% {
    margin-bottom: 2%;
    margin-top: 4%
  }
  100% {
    margin-bottom: 0;
    margin-top: 6%;
  }
}

.robot {
  max-width: 80%;
  max-height: 80%;
  height: auto;
  animation: robotanim 3s linear infinite;
}

.flexedColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.robotColumn {
  align-items: center;
  justify-content: end;
}

.Row {
  margin-bottom: 1em;
}

.moreInfoButton {
  width: fit-content;
  margin-bottom: 1em;
  margin-right: 1em;
}

.moreInfoContainer {
  margin-top: 1em;
  width: auto;
  max-width: max-content;
}

.moreInfoOverlay {
  position: absolute;
  width: 40em;
  aspect-ratio: 1;
  max-height: 90vh;
  border: 2px solid white;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: #6ca6c6f2;
  color: white;
  padding: 1em;
  box-shadow: 0 0 .5em #00000077;
}


@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .moreInfoOverlay {
    backdrop-filter: blur(80px);
    background: #3f7fa155;
    -webkit-backdrop-filter: blur(80px);
  }
}


.overlayHeader {
  display: flex;
  justify-content: flex-end;
  margin: 0 0;
}

.closeOverlayButton {
  width: auto;
  color: white;
}

.closeOverlayButton:hover {
  color: white;
  filter: drop-shadow(0 0 .15em #ffffffaa);
}

.closeOverlayButton:active {
  color: #aaaaaa !important;
  filter: drop-shadow(0 0 .15em #aaaaaaaa) !important;
}

.closeOverlayButton:focus-visible {
  color: white;
  filter: drop-shadow(0 0 .15em #ffffffaa);
  box-shadow: unset;
}

.overlayContent {
  margin: 1em 2em;
  overflow: auto;
}

.overlayTitle {

}

.exampleCard {
  flex: 1;
}

.overlayDescriptionCards {
  margin: 2em 0;
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 1em;
  align-items: flex-end;
}

.overlayDescription {
  margin: 1em 0;
}

.imageFrame {
  min-width: 6em;
  flex: 1;
}

.imageLabel {
  text-align: center;
  width: 100%;
  margin: .5em 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainImage {
  object-fit: contain;
  border-radius: .5em;
}

.bullet {
  width: .6em;
}

.badColor {
  color: var(--badColor);
}

.goodColor {
  color: var(--goodColor);
}

.badBorder {
  border: 5px solid var(--badColor);
}

.goodBorder {
  border: 5px solid var(--goodColor);
}
