:root {
  --gradient-dark: #3f7fa1;
  --gradient-light: #AFDDDD;
  --button-dark: #4b7b9c
}

@font-face {
  font-family: "FrutigerLTComBold";
  src: local("FrutigerLTComBold"),
  url("../fonts/FrutigerLTCom-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "FrutigerLTComLight";
  src: local("FrutigerLTComLight"),
  url("../fonts/FrutigerLTCom-Light.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "FrutigerLTComRoman";
  src: local("FrutigerLTComRoman"),
  url("../fonts/FrutigerLTCom-Roman.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "FrutigerLTCom-Bold";
  src: local("FrutigerLTComBold"),
  url("../fonts/FrutigerLTCom-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "FrutigerLTCom-Light";
  src: local("FrutigerLTComLight"),
  url("../fonts/FrutigerLTCom-Light.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "FrutigerLTCom-Roman";
  src: local("FrutigerLTComRoman"),
  url("../fonts/FrutigerLTCom-Roman.ttf") format("truetype");
  font-weight: bold;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
