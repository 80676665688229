.flippedArrow {
  transform: scaleX(-1);
}

.navArrowDisabled {
  filter: grayscale(100%) brightness(50%);
  cursor: inherit !important;
  visibility: hidden;
}

.navArrow {
  cursor: pointer;
  max-width: 100%;
  z-index: 1;
  user-select: none;
  transition: all .2s ease;
}

.navArrowContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.arrowTitle {
  text-align: center;
  padding-top: 8px;
}
