.overlayBackdrop {
    /*need a discreet layer for the blur*/
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(0.25turn, var(--gradient-dark), 40%, var(--gradient-light));
    width: 100%;
    height: 100%;
    z-index: 10;
}


@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .overlayGame {
      -webkit-backdrop-filter: blur(2em);
      backdrop-filter: blur(2em);
    }
    .overlayBackdrop {
        background: none;
    }
  }

.overlayGame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    /*backdrop-filter: blur(10px);*/
    overflow: auto;
    z-index: 11;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.robot{
    max-width: 35vw;
    max-height: 35vh;
    height: auto;
  }

.robotClass{
    position: absolute;
    right: 10%;
}