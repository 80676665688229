.header {
  display: flex;
  flex-direction: row;
  height: 160px;
  padding-bottom: 48px;
  margin-bottom: 20px;
}


.footer {
  display: flex;
  flex-direction: row;
  height: 64px;
}

.body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /*noinspection CssInvalidFunction*/
  background: linear-gradient(0.25turn, var(--gradient-dark), 40%, var(--gradient-light));
}

.bodyNoScroll {
  height: 100vh;
  overflow: clip;
  max-height: 100vh;
  /*noinspection CssInvalidFunction*/
  background: linear-gradient(0.25turn, var(--gradient-dark), 40%, var(--gradient-light));
}

.contentContainer {
  color: white;
  min-height: 0;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.contentContainerNoScroll {
  color: white;
  height: calc(100vh - 228px)
}

.profile {
  min-width: 200px;
  display: flex;
  align-items: flex-end;
  /*padding: 16px;*/
}

.logoutButton {
  padding: 4px 16px;
}
