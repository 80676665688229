.selector {
  margin: 0 80px;
}

.gameDetails {
  margin-bottom: 1em;
}

.gameListLoadingError {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.gameCardsRow {
  margin-top: 1em;
}

.gameCardContainer {

}

.gameCard {
  background-color: #00000000;
  border-color: #00000000;
  margin-bottom: 2em;
}

.gameCardHeader {
  display: flex;
  justify-content: space-between;
}

.gameCardImage {
  border-radius: 10px;
  object-position: center;
  object-fit: cover;
  cursor: pointer;
  aspect-ratio: 3/4;
  width: 100%;
  margin: 0.4em 0;
}

.gameCardButtonContainer {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  flex-wrap: wrap;
}

.gameCardButton {
  flex-grow: 2;
  margin: 0.3em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.gameButtonSvg {
  height: 1em;
}


.gameButtonText {
  margin-left: 0.2em;
}


.gameImageView {
  background: black;
  height: min-content;
  border-radius: 4vmin;
  overflow: clip;
  position: relative;
  width: 20vmin;
  height: 28vmin;
  margin-right: 3vmin;
  margin-bottom: 3vmin;

  transition-duration: 0.2s;
  float: left;
}

.gameImageView:hover {
  transform: scale(101%);
}

.gameImage {
  object-position: center;
  object-fit: cover;
  cursor: pointer;
  width: 100%;
}

.gameImageExpand {
  right: 24px;
  top: 24px;

  height: 4vmin;
  width: 4vmin;
  background: #a9a9a9bb;
  position: absolute;
  border-radius: 16px;
  transition-duration: 0.2s;

  cursor: pointer;
}

.expandIcon {
  width: 100%;
  height: 100%;
  transform: rotate(-45deg);
  display: flex;
  justify-content: center;
  align-items: center;
}

.gameImageExpand:hover {
  transform: scale(110%);
}