.clockCircle {
    height: 5em;
    width: 5em;

    border-width: 3px;
    border-radius: 50%;
    border-style: solid;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clockWhite {
    background-color: rgba(217, 216, 216, 0.6);
    border-color:  hsl(0, 0%, 100%);
}

.clockPurple {
    background-color: rgba(77, 80, 132, 0.6);
    border-color:  rgb(77, 80, 132);
}