.HeaderPages {
  font-family: "FrutigerLTComBold";
  font-size: 32px;
}

.SubHeaderPages {
  font-family: "FrutigerLTComRoman";
  font-size: 23px;
}

.HeaderPopups {
  font-family: "FrutigerLTComBold";
  font-size: 29px;
}

.SubHeaderPopups {
  font-family: "FrutigerLTComRoman";
  font-size: 23px;
}

.text, .button, .info {
  font-family: "FrutigerLTComRoman";
  font-size: 17px;
}

.text {
  hyphens: auto;
}

.footer {
  font-family: "FrutigerLTComRoman";
  font-size: 13px;
}

.smallButton {
  font-family: "FrutigerLTComRoman";
  font-size: 10px;
}