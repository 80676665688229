.modalBody {
  display: flex;
  flex-direction: column;

  height: 70vh;

  align-items: center;
  justify-content: center;
}

.detailsLogo {
  height: 10em;
  min-height: 10em;
  width: 100%;

  cursor: default;
  user-select: none;

  filter: invert(62%) sepia(5%) saturate(2344%) hue-rotate(147deg) brightness(110%) contrast(80%) drop-shadow(0 3px 6px rgba(79, 140, 169, 0.7));
}

.modal {

}

.mobileText {
  user-select: none;
  text-align: center;

  color: rgb(85, 119, 122);

  max-width: 13em;
}

.modal > :global(.modal-dialog) {
  min-width: 22rem;
}
