.sampleAndNavigationRow {
  border: 5px solid red;
}

.Row {
  margin-left: 0 !important;
  margin-bottom: 0 !important;
  
}

.game {
  width: 100%;
  flex: 1;
  height: 100vh;
  min-height: calc(100vh - 144px);
  min-width: 800px;
  margin: unset !important;
}

.slideImage.previous {
  transform: perspective(500px) rotateY(-45deg);
}

.slideImage.next {
  transform: perspective(500px) rotateY(45deg);
}

.slideImage.previousTwo {
  transform: perspective(500px) rotateY(-75deg) translateX(320px) scale(24%);
}

.slideImage.nextTwo {
  transform: perspective(500px) rotateY(75deg) translateX(-320px) scale(24%);
}

.slideImage.current,
.slideImage.next,
.slideImage.previous,
.slideImage.nextTwo,
.slideImage.previousTwo {
  opacity: 1;
}

.slideImage.previous,
.slideImage.next {
  z-index: -1;
}

.slideImage.current {
  transform: perspective(500px) scale(150%);
  z-index: 1;
}

.slideImage {
  transition: all .3s ease;
  height: 100%;
  position: relative;
  opacity: 0;
  display: flex;
  z-index: -2;
}


.slideImage.current .slideImageInner {
  filter: unset;
}

.slideImageInner {
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  border-radius: 16px;
  margin: auto;
  top: 0;
  bottom: 0;
  position: absolute;
  filter: contrast(50%) brightness(50%);
  transition: all .3s ease;
}

@media screen and (min-width: 1500px) {
  .slideImage.previous {
    transform: perspective(1000px) rotateY(-45deg);
  }

  .slideImage.next {
    transform: perspective(1000px) rotateY(45deg);
  }

  .slideImage.previousTwo {
    transform: perspective(1000px) rotateY(-75deg) translateX(420px) scale(40%);
  }

  .slideImage.nextTwo {
    transform: perspective(1000px) rotateY(75deg) translateX(-420px) scale(40%);
  }

  .slideImage.current {
    transform: perspective(1000px) scale(150%);
  }

  .slideImageInner {
    border-radius: 24px;
  }
}

@media screen and (min-width: 2300px) {
  .slideImage.previousTwo {
    transform: perspective(1100px) rotateY(-75deg) translateX(500px) scale(35%);
  }

  .slideImage.nextTwo {
    transform: perspective(1100px) rotateY(75deg) translateX(-500px) scale(35%);
  }
}

@media screen and (min-width: 2500px) {
  .slideImage.previousTwo {
    transform: perspective(1200px) rotateY(-75deg) translateX(550px) scale(35%);
  }

  .slideImage.nextTwo {
    transform: perspective(1200px) rotateY(75deg) translateX(-550px) scale(35%);
  }
}

@media screen and (min-width: 2700px) {
  .slideImage.previousTwo {
    transform: perspective(1300px) rotateY(-75deg) translateX(620px) scale(32%);
  }

  .slideImage.nextTwo {
    transform: perspective(1300px) rotateY(75deg) translateX(-620px) scale(32%);
  }
}

@media screen and (min-width: 3000px) {
  .slideImage.previous {
    transform: perspective(1300px) rotateY(-45deg);
  }

  .slideImage.next {
    transform: perspective(1300px) rotateY(45deg);
  }

  .slideImage.previousTwo {
    transform: perspective(1300px) rotateY(-75deg) translateX(680px) scale(30%);
  }

  .slideImage.nextTwo {
    transform: perspective(1300px) rotateY(75deg) translateX(-680px) scale(30%);
  }

  .slideImage.current {
    transform: perspective(1300px) scale(150%);
  }

  .slideImageInner {
    border-radius: 32px;
  }
}

@media screen and (min-width: 3300px) {
  .slideImage.previousTwo {
    transform: perspective(1300px) rotateY(-75deg) translateX(720px) scale(28%);
  }

  .slideImage.nextTwo {
    transform: perspective(1300px) rotateY(75deg) translateX(-720px) scale(28%);
  }
}

@media screen and (min-width: 3600px) {
  .slideImage.previousTwo {
    transform: perspective(1300px) rotateY(-75deg) translateX(780px) scale(25%);
  }

  .slideImage.nextTwo {
    transform: perspective(1300px) rotateY(75deg) translateX(-780px) scale(25%);
  }
}

@media screen and (min-width: 4500px) {
  .slideImage.previous {
    transform: perspective(2000px) rotateY(-45deg);
  }

  .slideImage.next {
    transform: perspective(2000px) rotateY(45deg);
  }

  .slideImage.previousTwo {
    transform: perspective(1300px) rotateY(-75deg) translateX(900px) scale(20%);
  }

  .slideImage.nextTwo {
    transform: perspective(1300px) rotateY(75deg) translateX(-900px) scale(20%);
  }

  .slideImage.current {
    transform: perspective(2000px) scale(150%);
  }

  .slideImageInner {
    border-radius: 48px;
  }
}

@media screen and (min-width: 6000px) {
  .slideImage.previous {
    transform: perspective(3000px) rotateY(-45deg);
  }

  .slideImage.next {
    transform: perspective(3000px) rotateY(45deg);
  }

  .slideImage.current {
    transform: perspective(3000px) scale(150%);
  }

  .slideImageInner {
    border-radius: 96px;
  }
}

@media screen and (min-width: 7500px) {
  .slideImage.previous {
    transform: perspective(3500px) rotateY(-45deg);
  }

  .slideImage.next {
    transform: perspective(3500px) rotateY(45deg);
  }

  .slideImage.previousTwo {
    transform: perspective(3000px) rotateY(-75deg) translateX(1820px) scale(25%);
  }

  .slideImage.nextTwo {
    transform: perspective(3000px) rotateY(75deg) translateX(-1820px) scale(25%);
  }

  .slideImage.current {
    transform: perspective(3500px) scale(150%);
  }

  .slideImageInner {
    border-radius: 96px;
  }
}

@media screen and (min-width: 9000px) {
  .slideImage.previous {
    transform: perspective(4500px) rotateY(-45deg);
  }

  .slideImage.next {
    transform: perspective(4500px) rotateY(45deg);
  }

  .slideImage.previousTwo {
    transform: perspective(3000px) rotateY(-75deg) translateX(2100px) scale(20%);
  }

  .slideImage.nextTwo {
    transform: perspective(3000px) rotateY(75deg) translateX(-2100px) scale(20%);
  }

  .slideImage.current {
    transform: perspective(4500px) scale(150%);
  }

  .slideImageInner {
    border-radius: 96px;
  }
}

.previousArrow {
}

.nextArrow {
  transform: scaleX(-1);
}

.navArrowDisabled {
  filter: grayscale(100%) brightness(50%);
  cursor: inherit !important;
}

.navArrow {
  cursor: pointer;
  max-width: 100%;
  z-index: 1;
  user-select: none;
  transition: all .2s ease;
}

.navContainerPrevious {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.navContainerNext {
  display: flex;
  align-items: center;
}

.gameSlider {
  height: 80%;
  width: 100%;
  overflow: visible;
}

.gameSlider > :global(.slick-list) {
  overflow: visible;
}

.gameSliderContainer {
  padding: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  min-height: 50%;
  max-height: 80%;
  width: 100%;
  /*max-width: 60%;*/
  height: 100%;
  aspect-ratio: 16/9;
  flex: 2;
}

.gameHeaderRowContainer {
  min-height: calc(100vh - 144px);
  display: flex;
  flex-direction: column;

}

.gameHeaderExamplesAndButtonRow {
  flex-grow: 1;
  
  /*handles gutters from bootrap row*/
  margin-left: 0 !important;

  align-items: center;
}


.gameHeader {
  padding-bottom: 16px;
}

.goToGameButtonColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1em;
}

.goToGameButtonContainer {
  color: white ;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid white;
  padding: 1em 2em;
  margin: 0 3em;
  border-radius: 50px;
  cursor: pointer;
}

.goToGameText {
  color: white;
  flex: 1;
  text-align: center;
}

.goToGameButtonIcon {
  color: white;
  margin: 0 0.5em 0 0;
  --fa-bounce-height: 8px;
}

.gameDescription {
  max-width: 90%;
  padding-bottom: 10px;
}

.gameSlider :global(.slick-list) {
  height: 100%;
}

.gameSlider :global(.slick-list) :global(.slick-track) {
  height: 100%;
}

.gameSlider :global(.slick-list) :global(.slick-track) :global(.slick-slide) div {
  height: 100%;
}

.gameSlider :global(.slick-dots) {
  bottom: 0;
}

.gameCol {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  align-items: center;
  justify-content: center;
}

.clock {
  padding: 0 16px;
}

.voteButton {
  --highlight-color: white;
  padding: 16px 8px;
  min-width: 128px;
  text-align: center;
  border-radius: 50px;
  color: white;
  cursor: pointer;
  transition: all .1s linear;
  user-select: none;
}

.voteButton:hover {
  scale: 110%;
}

.yesButton {
  --highlight-color: #9F1E1E;
  background: #CC2727aa;
  outline-color: var(--highlight-color);
}

.noButton {
  --highlight-color: #13775f;
  background: #24EBB6aa;
  outline-color: var(--highlight-color);
}

.selectedButton {
  outline-width: 5px;
  outline-style: solid;
  font-weight: bold;
  color: var(--highlight-color);
}

.gameControlsRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  flex-grow: 0;
  margin: 16px 0;
}

.gameControlsRow *:last-child {
  margin: 0;
}

.gameControlsRow * {
  margin: 0 8px 0 0;
}

.robot{
  max-height: 10em;
}

.gameHeaderColumn {
  height: fit-content;
}

.exampleContainer {
  display: flex;
  padding: 0 0;
  gap: 1em;
  margin: 1em 0 1em 0;
  align-items: flex-end;
}

.exampleCard {
}


.imageFrame {
  min-width: 6em;
  flex: 1;
}

.imageLabel {
  text-align: center;
  width: 100%;
  margin: .5em 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainImage {
  object-fit: contain;
  border-radius: .5em;
}

.bullet {
  width: .6em;
}

.badColor {
  color: var(--badColor);
}

.goodColor {
  color: var(--goodColor);
}

.badBorder {
  border: 5px solid var(--badColor);
}

.goodBorder {
  border: 5px solid var(--goodColor);
}

