.langToggle {
  width: 256px;
  color: white;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.langLabel {
  padding: 4px 0;
  font-size: 13px;
  white-space: nowrap;
}

.langButton {
  margin: 8px 0 0 0 !important;
}

.langDropdownButton {

}

.langDropdownButton button:after {
  display: none !important;
}

.langToggleCenter {
  align-items: center;
}
