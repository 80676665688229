.explainCarousel {
  flex: 1;
  width: 100%;
  overflow: hidden;
}

.navContainerPrevious {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.navContainerNext {
  display: flex;
  align-items: center;
}

.explainSlideImage {
  object-position: center;
  object-fit: contain;

  width: 100%;
  margin: 1em 0;
  border-radius: 20%;
}

.explainSlide {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 0 0 1em;
}

.explainCarouselContainer {
  width: 100%;
  overflow: hidden;
  align-items: center;
}

.alignRight {
  flex-direction: row-reverse;
  padding: 0 2em 0 0;
}

.alignRight > * {
  align-items: flex-end;
  text-align: right;
}


.explainSlideDetails {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 80%;
  height: 100%;
  padding: 1em 2em;
  overflow: auto;
}

.explainSlideIntroduction {
  max-width: 90%;
  line-height: 1.2em;
  padding: 0 8px 0 0;
}

.explainSlideDescription {
  max-width: 90%;
  line-height: 1.2em;
  padding: 0 0 8px 0;
}

.descriptionParagraph {
  margin-bottom: 0.5em;
  display: inline-block;
}

.explainSlideTitle {
  height: unset !important;
}

.explainCarouselSlick {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.carouselDot {
  border-radius: 50%;
  height: 16px;
  width: 16px;
  border: 2px solid white;
  background: transparent;
}

.explainCarouselSlick :global(.slick-list) {
  height: 100%;
}

.explainCarouselSlick :global(.slick-list) :global(.slick-track) {
  height: 100%;
}

.explainCarouselSlick :global(.slick-list) :global(.slick-track) :global(.slick-slide) div {
  height: 100%;
}

.explainCarouselSlick :global(.slick-dots) {
  bottom: 0;
}

.explainCarouselSlick :global(.slick-dots) :global(.slick-active) div {
  background: white;
}
