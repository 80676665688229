:root {
  --badColor: red;
  --goodColor: #24eab5;
}


.imageFrame {
  width: 100%;
  /*height: 8em;*/
  aspect-ratio: 1;
  text-align: center;
  display: flex;
  align-items: flex-end;
}

.mainImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: scale-down;
  border-radius: 5%;
}

.prediction {
  display: flex;
  flex: 1;
  align-items: center;
  height: 4em !important;
  justify-content: left;

}

.bullet {
  width: 0.7vw;
}

.badColor {
  color: var(--badColor);
}

.goodColor {
  color: var(--goodColor);
}

.badBorder {
  border: 5px solid var(--badColor);
}

.goodBorder {
  border: 5px solid var(--goodColor);
}

.finalScore {
  text-align: center;
  margin-bottom: 0.8em;
  /**/
  height: 3.4em;
}

.cardOuterStyle {
  margin-top: 1vmin;
}

.gameCard {
  border: none;
  background-color: transparent;
}

.gameCardBody {
  padding: 0.5em 0.5em !important;
}

.buttonNoVisible {
  visibility: hidden;
}

