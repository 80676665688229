.videoElement {
  max-height: 100%;
  margin: 0 48px;
  width: 100%;
}

.videoElement:first-of-type {
  margin-left: 0;
}

.videoElement:last-of-type {
  margin-right: 0;
}

.videoImageContainer {
  width: 100%;
  background: gray;
  aspect-ratio: 16 / 9;
  position: relative;
  overflow: clip;
}

.videoImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.videoImageOverlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  transition: all 0.2s ease-in-out;
}

.videoImageOverlay:hover {
  background: rgba(255, 255, 255, 0.5);
}

.videoImageOverlay:hover .videoPlayIcon {
  color: rgba(77, 80, 132, 0.8);
  scale: 120%;
}

.videoImageOverlay .videoPlayIcon {
  color: rgba(255, 255, 255, 0.8);
}

.videoPlayIcon {
  transition: all 0.2s ease-in-out;
}

.videoTitle {
  margin: 32px 0 8px 0;
  min-height: 2.3em;
  line-height: 1.2em;
}

.videoDetails {
  max-height: 100%;
}

.videoModal div div {
  overflow: clip !important;
}

.videoDescription {
  /*text-align: justify !important;*/
  line-height: 1.35em;
  hyphens: auto;
}