.body {
  display: flex;
  flex-direction: column;
  padding: 0 10vmin;
  align-items: flex-start;
  overflow: auto;
  max-width: 1000px;
}

.detailsHeader {
  padding: 8px 0 0 0;
}

.link {
  color: white;
  margin-left: 1em;
}

.paragraph {
  margin-top: 3em;
}