.loadingGame{
  width: 100%;
  height: 100%;
  position: relative;
}

.loadIcon{
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
  width: 140px;
  height: 140px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -70px 0 0 -70px;
  background-color: transparent;
}


@-moz-keyframes spin { 
  100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
  100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
  100% { 
      -webkit-transform: rotate(360deg); 
      transform:rotate(360deg); 
  } 
}
