.explainContainer {
  padding: 0 80px !important;
}

.Row {
  margin-bottom: 1em;
}
.explain {
  padding: 0 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  height: 100%;
}

.explainTitle {
  flex: 1;
}

.explainTitleContainer {
  display: flex;
  width: 90%;
  flex-direction: row;
  align-items: center;
}

.explainDescription {
  padding: 8px 0;
  width: 60%;
}

.explainCommonDescription {
  padding: 8px 0;
  width: 90%;
}

.explainDetails {
  width: 100%;
  overflow: auto;
}

.backButton {
  margin: 5em 0 2em 0;
}

.backButtonContainer {
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 1em;
}

.explainBlock {
  display: flex;
  gap: 5%;
}

.smallContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.robot {
  max-width: 8em;
  max-height: 8em;
  height: auto;
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 32px;
}

