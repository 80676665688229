.navLink {
  padding-left: 24px !important;
  padding-right: 24px !important;
  font-size: 20px;
  font-family: "Helvetica Neue", "Roboto Light", "Segoe UI", sans-serif;
  height: min-content;
}

.navbar {
  flex: 1;
  color: whitesmoke;
  height: 100%;
  padding: 0 !important;
}

.navLinkActive {
  color: var(--bs-navbar-active-color) !important;
}

.navLink:after {
  content: "";
  float: left;
  background: white;
  width: 100%;
  height: 4px;
  border-radius: 3px;
  margin-top: 2px;
  visibility: hidden;
}

.navLinkActive:after {
  visibility: visible;
}

.navbarLogo {
  padding: 0 56px !important;
  font-size: 24px !important;
  height: 100%;
}

.pneumoAILogo {
  height: 135%;
  filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.30));
  transition: all 0.2s ease-in-out;
}

.pneumoAILogo:hover {
  filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.60));
}

.navbarNav {
  height: 100% !important;
  overflow: visible;
  align-items: flex-end;
}