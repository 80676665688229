.metricRow {
  margin: 3em;
  display: flex;
  align-items: center;
}

.progressCol {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.progressCol > div {
  flex-grow: 1;
  margin-right: 1em;
}

.centerCol {
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: center;
  margin-right: 1em;
}

.progressBar {
  background-color: white;
}

.progressBarParent {
  background-color: rgba(255, 255, 255, 0.4);
}

.container {
  margin: 3em auto;
}

.closeButton {
  padding-right: 2em;
  padding-left: 2em;
}

.closeButton {
  padding-right: 2em;
  padding-left: 2em;
}

.newButtonText {
  margin-left: 0.2em;
}

.detailTypeText {
  padding-left: 4em;
}

.caption {
  max-width: 60%;
  margin: 0 3em;
}

.title {
  margin: 0 1.6em;
}
