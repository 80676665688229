.row {
  margin-bottom: 3em;
}

.titleCard {
  border: none !important;
  background: unset !important;
}

.placeHolderDetailContainer {
  visibility: hidden;
}

.titleCardBody {
  padding: 0.5em 0.5em !important;
}

.backButton {
  margin: 1.5em
}

.imageDivPlaceholder {
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 5%;
}

.containerGameScoreBoard {
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 5vmin;
  align-items: center;
}

.iconNoVisible {
  visibility: hidden;
}

.captionBar {
  padding: 0 1.5em;
}

.rightButtonBar {
  padding: 0 1.5em;
  gap: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-content: flex-start;
}

.rightButtonBar > button {
}

.titlePlaceHolder {
  text-align: center;

  height: 4.2em;
}

.predictionLabel {
  display: flex;
  justify-content: flex-start;
  flex: 1;
  align-items: center;
  height: 4em !important;
}

.titleCardOuterStyle {
  margin-top: 1vmin;
}

.button {
  margin: 0 0.3em;
}

.robot {
  max-width: 8em;
  max-height: 8em;
}

.robotBox {
  margin: 3em;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.resultColumn {
  margin-bottom: 2em;
}